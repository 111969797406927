import { useEffect } from 'react';

import { getNavbarOpacityByTransitionDistance } from '@/utility/helpers';

const useAnimateOpacityByScrolling = (
  transition: { end: number; start: number },
  elementRef: React.RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    if (elementRef.current) {
      if (transition) {
        elementRef.current.style.backgroundColor = `rgba(255, 255, 255, 0)`;
        elementRef.current.style.transition =
          'background-color 0.1s ease-in-out';

        const handleScroll = () => {
          const opacity = getNavbarOpacityByTransitionDistance(transition);
          elementRef.current.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`;
        };

        // Call handleScroll immediately to set the initial opacity
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      } else {
        elementRef.current.style.backgroundColor = `rgba(255, 255, 255, 1)`;
      }
    }
  }, [transition, elementRef]);
};

export default useAnimateOpacityByScrolling;
